@font-face {
  font-family: "Omnes";
  src: url("./fonts/Omnes.ttf");
}

@font-face {
  font-family: "Open Sans";
  src: url("./fonts/OpenSans-Regular.ttf");
}

@font-face {
  font-family: "Open Sans";
  src: url("./fonts/OpenSans-Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: "Open Sans";
  src: url("./fonts/OpenSans-Italic.ttf");
  font-style: italic;
}

@font-face {
  font-family: "Open Sans";
  src: url("./fonts/OpenSans-BoldItalic.ttf");
  font-weight: bold;
  font-style: italic;
}

body {
  margin: 0;
  font-family: "Omnes", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: rgb(51, 51, 51);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}
